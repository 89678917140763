import { COLORS } from './colors';
import {
  ACCOUNT_DATA_SCHEME,
  CONTACT_DATA_SCHEME,
  LINKED_ITEM_ID_NAMES,
  OPPORTUNITY_DATA_SCHEME,
  PLAN_DATA_ACCOUNT_ID_SCHEME,
  POLICY_DATA_ACCOUNT_ID_SCHEME,
  SALES_PIPELINE_DATA_SCHEME,
  TASK_DATA_SCHEME
} from '@constants/linkedItem';

import { getOverdueFilterParamsObject } from '@/shared/utils/getOverdueFilterParamsObject';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { ColumnType } from '@components/Table/components/Header/types';
import { SchemeDataName, LinkedItemSchemeType, TodoPopupSettings } from '@/shared/types/todos';

export const INIT_TODO_POPUP_SETTINGS: TodoPopupSettings = {
  priority: [],
  category: [],
  recurringFrequency: [],
  stage: [],
};

export const TO_DOS_FIELDS_NAMES = {
  assignedBy: 'assignedBy',
  createdDate: 'createdDate',
  assignedTaskInstructions: 'assignedTaskInstructions',
  assignId: 'assignId',
  category: 'category',
  createdBy: 'createdBy',
  createFUTask: 'createFUTask',
  createFUTaskForAssignerToMonitor: 'createFUTaskForAssignerToMonitor',
  description: 'description',
  dueDate: 'dueDate',
  dueDateType: 'dueDateType',
  endDate: 'endDate',
  frequency: 'frequency',
  id: 'id',
  isLinkedContact: 'isLinkedContact',
  isNew: 'isNew',
  linkedItemData: 'linkedItemData',
  linkedContactId: 'linkedContactId',
  linkedItemId: 'linkedItemId',
  name: 'name',
  nextAppointmentDate: 'nextAppointmentDate',
  noteContactId: 'noteContactId',
  noteId: 'noteId',
  noteTagId: 'tagId',
  noteTags: 'tags',
  noteTagValue: 'value',
  noteText: 'noteText',
  noteTitle: 'title',
  noteType: 'noteType',
  previousToggleState: 'previousToggleState',
  priority: 'priority',
  request: 'request',
  requirring: 'requirring',
  reviewDate: 'reviewDate',
  stage: 'stage',
  taskNotes: 'taskNotes',
} as const;

export const TODOS_FILTER_NAMES = {
  category: 'category',
  classification: 'classification',
  endDate: 'endDate',
  linkedContactId: 'linkedContactId',
  overdue: 'overdue',
  priority: 'priority',
  request: 'request',
  stage: 'stage',
  startDate: 'startDate',
  type: 'type',
  users: 'assignId',
} as const;

export const TODOS_COLUMNS_IDS = {
  name: 'name',
  linkedContactName: 'linkedContactName',
  stage: 'stage',
  priority: 'priority',
  dueDate: 'dueReviewDate',
  lastUpdated: 'lastUpdated',
  assignTo: 'assignTo',
  complete: 'complete',
};

export const COLUMNS: Array<ColumnType> = [
  {
    id: TODOS_COLUMNS_IDS.name,
    label: 'Task Name',
    style: {
      width: '20%'
    },
    sortable: true,
  },
  {
    id: TODOS_COLUMNS_IDS.linkedContactName,
    label: 'Linked Items',
    style: {
      width: '23%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: TODOS_COLUMNS_IDS.priority,
    label: 'Priority',
    style: {
      width: '10%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: TODOS_COLUMNS_IDS.stage,
    label: 'Stage',
    style: {
      width: '11%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: TODOS_COLUMNS_IDS.dueDate,
    label: 'Due/Review Date',
    style: {
      width: '150px',
    },
    sortable: true,
    separator: true,
  },
  {
    id: TODOS_COLUMNS_IDS.lastUpdated,
    label: 'Last Updated',
    style: {
      width: '120px'
    },
    separator: true,
  },
  {
    id: TODOS_COLUMNS_IDS.assignTo,
    label: 'Owner',
    style: {
      width: '50px'
    },
    separator: true,
  },
  {
    id: TODOS_COLUMNS_IDS.complete,
    label: '',
    style: {
      width: '70px'
    },
  },
];

export const PRIORITIES = {
  none: 'None',
  low: 'Low',
  normal: 'Normal',
  high: 'High',
  urgent: 'Urgent'
} as const;

export const PRIORITIES_OPTIONS = optionsFromValue(Object.values(PRIORITIES));

export const CATEGORIES = {
  officialRequests: 'Official Requests',
  general: 'General',
  trading: 'Trading',
  moveMoney:  'Move Money',
  scheduling: 'Scheduling',
  paperWork:  'Paper Work',
  transfers: 'Transfers',
  funding: 'Funding',
  clientService:  'Client Service',
  project: 'Project',
  opportunities: 'Opportunities',
  personal: 'Personal',
  planning: 'Planning',
  other: 'Other',
} as const;

export const OFFICIAL_REQUEST_SUBCATEGORIES = {
  primaryTradeRequest: 'Primary Trade Request',
  mmRequest: 'MM Request',
  schwabService: 'Schwab Service',
} as const;


export const DUE_DATE_TYPES = {
  mustDoToday: 'Must Do Today',
  hardDeadline: 'Hard Deadline',
  targetOrSomeFlex: 'Target/Some Flex',
  tentative: 'Tentative',
} as const;

export const DUE_DATE_TYPES_OPTIONS = optionsFromValue(Object.values(DUE_DATE_TYPES));

export const TO_DOS_LINKED_ITEMS_IDS = {
  accountId: LINKED_ITEM_ID_NAMES.accountId,
  linkedContactId: LINKED_ITEM_ID_NAMES.linkedContactId,
  opportunityId: LINKED_ITEM_ID_NAMES.opportunityId,
  planId: LINKED_ITEM_ID_NAMES.accountId,
  policyId: LINKED_ITEM_ID_NAMES.accountId,
  salesPipeLineId: LINKED_ITEM_ID_NAMES.salesPipelineId,
  taskId: LINKED_ITEM_ID_NAMES.taskId,
} as const;

export const TO_DOS_LINKED_ITEMS_TYPES_SCHEME: Record<SchemeDataName, LinkedItemSchemeType> = {
  ...CONTACT_DATA_SCHEME,
  ...OPPORTUNITY_DATA_SCHEME,
  ...ACCOUNT_DATA_SCHEME,
  ...PLAN_DATA_ACCOUNT_ID_SCHEME,
  ...POLICY_DATA_ACCOUNT_ID_SCHEME,
  ...SALES_PIPELINE_DATA_SCHEME,
  ...TASK_DATA_SCHEME
};

export const CATEGORIES_OPTIONS = optionsFromValue(Object.values(CATEGORIES));

export const DEFAULT_CATEGORY = CATEGORIES.general;

export const DEFAULT_PRIORITY = PRIORITIES.none;

export const PRIORITIES_COLOR_MAP = [
  {
    value: PRIORITIES.none,
    color: COLORS.borders,
  },
  {
    value: PRIORITIES.low,
    color: COLORS.green,
  },
  {
    value: PRIORITIES.normal,
    color: COLORS.blue,
  },
  {
    value: PRIORITIES.high,
    color: COLORS.yellow,
  },
  {
    value: PRIORITIES.urgent,
    color: COLORS.red,
  },
];

export const ALL_TASKS_OPTION = {
  label: 'All Tasks',
  value: 'all'
};

export const ALL_OVERDUE_AND_DUE_OPTION = {
  label: 'All Overdue & Due',
  value: 'allOverdueAndDue'
};

export const OVERDUE_AND_DUE_TODAY_OPTION = {
  label: 'Overdue & Due Today',
  value: 'overdueAndDueToday'
};

export const OVERDUE_AND_DUE_WITH_ONE_WEEK_OPTION = {
  label: 'Overdue & Due Within One Week ',
  value: 'overdueAndDueWithinOneWeek'
};

export const DUE_TODAY_OPTION = {
  label: 'Due Today',
  value: 'dueToday'
};

export const DUE_WITHIN_ONE_WEEK_OPTION = {
  label: 'Due Within One Week',
  value: 'dueWithOneWeek'
};

export const DUE_WITHIN_ONE_MONTH_OPTION = {
  label: 'Due Within One Month',
  value: 'dueWithOneMonth'
};

export const DUE_WITHIN_ONE_YEAR_OPTION = {
  label: 'Due Within One Year',
  value: 'dueWithOneYear'
};

export const NO_DUE_DATE = {
  label: 'No Due Date',
  value: 'noDueDate'
};

export const DEFAULT_OVERDUE_FILTER_TYPE = OVERDUE_AND_DUE_WITH_ONE_WEEK_OPTION.value;

export const OVERDUE_FILTER_OPTIONS = [
  ALL_TASKS_OPTION,
  ALL_OVERDUE_AND_DUE_OPTION,
  OVERDUE_AND_DUE_TODAY_OPTION,
  OVERDUE_AND_DUE_WITH_ONE_WEEK_OPTION,
  DUE_TODAY_OPTION,
  DUE_WITHIN_ONE_WEEK_OPTION,
  DUE_WITHIN_ONE_MONTH_OPTION,
  DUE_WITHIN_ONE_YEAR_OPTION,
  NO_DUE_DATE,
];

export const STAGES = {
  pending: 'Pending',
  inProgress: 'In Progress',
  waitingOn: 'Waiting On',
  future: 'Future',
  complete: 'Complete',
  skipped: 'Skipped',
  forNextMeeting: 'For Next Meeting',
  assigned: 'Assigned',
  assignedFU: 'Assigned F/U',
  assignedTo3rdParty: 'Assigned to 3rd Party',
  monitoring: 'Monitoring',
} as const;

export const EDIT_MODE_STAGES_OPTIONS = optionsFromValue(Object.values(STAGES));

export const MAIN_STAGES_OPTIONS = optionsFromValue(Object.values(STAGES).filter((stage) => stage !== STAGES.skipped));

export const DEFAULT_STAGE = STAGES.assigned;

export const CORE_CLASSIFICATIONS = {
  client: 'Client',
  lead: 'Lead',
  prospect: 'Prospect',
  general: 'General',
} as const;


export const INIT_STAGE_FILTER_STATE = Object.values(STAGES).filter(item => {
  return ![STAGES.complete, STAGES.skipped].includes(item as typeof STAGES.complete | typeof STAGES.skipped);
});

export const TODOS_GRID_TYPES = {
  clientSpecific: 'Client-Specific',
  other: 'Other'
};

export const CLIENT_SPECIFIC_GRID_FILTER_TYPE = TODOS_GRID_TYPES.clientSpecific;
export const OTHER_GRID_FILTER_TYPE = TODOS_GRID_TYPES.other;

const COMMON_INIT_FILTERS_STATE = {
  [TODOS_FILTER_NAMES.stage]: INIT_STAGE_FILTER_STATE,
  [TODOS_FILTER_NAMES.users]: [],
  [TODOS_FILTER_NAMES.priority]: [],
  [TODOS_FILTER_NAMES.classification]: [],
};

export const INIT_CLIENT_SPECIFIC_FILTERS_STATE = {
  ...COMMON_INIT_FILTERS_STATE,
  [TODOS_FILTER_NAMES.type]: CLIENT_SPECIFIC_GRID_FILTER_TYPE,
};

export const INIT_OTHER_FILTERS_STATE = {
  ...COMMON_INIT_FILTERS_STATE,
  [TODOS_FILTER_NAMES.type]: OTHER_GRID_FILTER_TYPE,
};

export const INIT_OVERDUE_FILTER_STATE = {
  type: DEFAULT_OVERDUE_FILTER_TYPE,
  params: getOverdueFilterParamsObject(DEFAULT_OVERDUE_FILTER_TYPE)
};

export const YES_NO_TO_BOOLEAN_REQUIRRING_FIELD: Array<string> = [TO_DOS_FIELDS_NAMES.requirring];
